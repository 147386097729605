// DotDotDotLoader.jsx
import React, { useState, useEffect } from 'react';
    
const DotDotDotLoader = () => {
  const [dots, setDots] = useState('');

  useEffect(() => {
    const interval = setInterval(() => {
      setDots(prevDots => (prevDots.length < 3 ? prevDots + '.' : ''));
    }, 500); // Adjust the interval as needed

    return () => clearInterval(interval);
  }, []);

  return <div className="dot-dot-dot-loader">{dots}</div>;
};

export default DotDotDotLoader;
