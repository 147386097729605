import React, { useEffect, useRef } from "react";
import meta from "../img/meta.png";
import brave from "../img/brave.png";
import trust from "../img/trust.png";
import connect from "../img/connect.png";
const ConnectModel = ({ showModel, setShowModel, connectMetaMask }) => {
  const onHandleClick = (type) => {
    connectMetaMask(type);
    setShowModel(false);
  };
  const modalRef = useRef();
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (modalRef.current && !modalRef.current.contains(event.target)) {
        setShowModel(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [setShowModel]);

  return (
    <>
      <div
        className={`modal ${showModel && "show"}`}
        id="myModal"
        style={{
          display: showModel ? "block" : "none",
          background: showModel ? "#07070787" : "transparant",
        }}
        role={showModel && "dialog"}
      >
        <div className="modal-dialog modal-dialog-centered">
          <div className="modal-content" ref={modalRef}>
            <div className="modal-header">
              <h4 className="modal-title">Connect a wallet</h4>
            </div>

            <div className="modal-body">
              <div
                className="modalMenu d-flex"
                onClick={() => {
                  setShowModel(false);
                  onHandleClick("metaMask");
                }}
              >
                <div className="wallet_img">
                  <img src={meta} alt="meta" />
                </div>
                <div className="modalText">Meta Mask</div>
                <div className="modalStatus ms-auto">
                  <span className="badge badge-sm badge-warning">Recent</span>
                </div>
              </div>
              <div
                className="modalMenu d-flex mt-2"
                onClick={() => {
                  setShowModel(false);
                  onHandleClick("brave");
                }}
              >
                <div className="wallet_img">
                  <img src={brave} alt="meta" />
                </div>
                <div className="modalText">Brave Wallet</div>
              </div>
              <div
                className="modalMenu d-flex mt-2"
                onClick={() => {
                  setShowModel(false);
                  onHandleClick("trust");
                }}
              >
                <div className="wallet_img">
                  <img src={trust} alt="meta" />
                </div>
                <div className="modalText">Trust Wallet</div>
              </div>
              <div
                className="modalMenu d-flex mt-2"
                onClick={() => {
                  setShowModel(false);
                  onHandleClick("walletConnect");
                }}
              >
                <div className="wallet_img">
                  <img src={connect} alt="meta" />
                </div>
                <div className="modalText">Wallet Connect</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ConnectModel;