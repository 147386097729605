export const initialState = {
    data: {},
    loading: true,
  };

  
  export function Get_user_token_list(state = initialState, action) {
    // console.log(action.payload,"action");
    switch (action.type) {
      case "GET_TOKEN_LIST":
        return { data: action.payload, loading: false };
      default:
        return state;
    }
}
  
export function Token_by_influencer(state = initialState, action) {
    // console.log(action.payload,"action");
    switch (action.type) {
      case "TOKENINFLUENCER_DATA":
        return { data: action.payload, loading: false };
      default:
        return state;
    }
}
  
















  