import React from 'react'
import { Link } from 'react-router-dom'
import logo from "../img/logo.png"
import Footer from './Footer'
const About = () => {
    return (
        <div className='contentPage'> <header className="headerLink">
            <div className="container">
                <div className="row align-items-center">
                    <div className="col-12 text-center">
                        <Link to="/"><img src={logo} alt="logo" className="instatoken_logo" /></Link>
                    </div>
                </div>
            </div>
        </header>
            <section className="sectionLink ">
                <div className="container">
                    <div className="pt-2 pt-lg-5 pb-5 pl-5 pr-5">
                        <h1>About INSTATOKEN</h1>

                        <p>INSTATOKEN is a pioneering platform in the DAWAP3 space, designed to simplify the process of receiving desired tokens. Our mission is to make token exchanges fast, secure, and user-friendly.</p>

                        <h2>What We Do</h2>

                        <p>INSTATOKEN allows users to send native tokens to specific smart contract addresses and receive the desired tokens in return. This eliminates the need for complex transactions on decentralized exchanges. With INSTATOKEN, users can effortlessly convert their assets with just a few clicks.</p>

                        <h2>Our Vision</h2>

                        <p>We envision a decentralized future where token exchanges are seamless and accessible to everyone. By leveraging smart contracts and blockchain technology, we aim to create a robust and transparent ecosystem for digital assets.</p>

                        <h2>Our Team</h2>

                        <p>Our team consists of experienced professionals in blockchain technology and finance. We are dedicated to providing innovative solutions that enhance the user experience in the cryptocurrency space.</p>

                        <h2>Contact Us</h2>

                        <p>For more information or support, please reach out to us at <a href='mailto:support@instatoken.io'>support@instatoken.io</a> We are here to help you with any questions or concerns.</p>
                    </div>
                </div>
            </section><Footer /></div>
    )
}

export default About