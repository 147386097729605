import React from 'react'
import { Link } from 'react-router-dom'
import logo from "../img/logo.png"
import Footer from './Footer'
const Privacy = () => {
    return (
        <div className='contentPage'><header className="headerLink">
            <div className="container">
                <div className="row align-items-center">
                    <div className="col-12 text-center">
                        <Link to="/"><img src={logo} alt="logo" className="instatoken_logo" /></Link>
                    </div>
                </div>
            </div>
        </header>
            <section className="sectionLink">
                <div className="container">
                    <div className="pt-2 pt-lg-5 pb-5 pl-5 pr-5">
                        <h1>Privacy Policy for INSTATOKEN</h1>

                        <p>At INSTATOKEN, we value your privacy and are committed to protecting your personal information. This Privacy Policy outlines how we collect, use, and safeguard your data when you use our services.</p>

                        <h2>Information We Collect</h2>
                        <ul>
                            <li><b>Personal Information:</b> When you interact with our platform, we may collect personal information such as your name, email address, and wallet address.</li>
                            <li><b>Usage Data:</b> We collect data on how you use our services, including transaction history and interactions with our smart contracts.</li>
                        </ul>
                        <h2>How We Use Your Information</h2>
                        <ul>
                            <li>To Provide Services: We use your personal and usage information to facilitate transactions and provide customer support.</li>
                            <li>To Improve Our Services: We analyze usage data to enhance the performance and security of our platform.</li>
                            <li>Communication: We may use your email to send updates, security alerts, and other information related to your use of INSTATOKEN.</li>
                        </ul>
                        <h2>Data Security</h2>

                        <p>We implement advanced security measures to protect your data from unauthorized access, alteration, or destruction. However, please note that no method of transmission over the Internet or electronic storage is completely secure.</p>

                        <h2>Your Rights</h2>

                        <p>You have the right to access, update, or delete your personal information. If you wish to exercise these rights, please contact our support team.</p>

                        <h2>Changes to This Policy</h2>

                        <p>We may update this Privacy Policy periodically. Any changes will be posted on this page, and we will notify you via email or through our platform.</p>

                    </div>
                </div>
            </section>
            <Footer /></div>
    )
}

export default Privacy