
import "./App.css";
// import "./css/style.css";
import Home from "./components/Home";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import SwapToken from "./components/SwapToken";
// require('dotenv').config()
import About from "./components/About";
import Condition from "./components/Condition";
import Privacy from "./components/Privacy";

function App() {
  return (
    <>
    <div className="scrollbar-thin scroll-table scroll-main">

      <BrowserRouter >
        <Routes>
          <Route exact path="/" element={<Home />} />
          <Route exact path="/wallet" element={<SwapToken />} />
          <Route exact path="/about" element={<About/>} />
          <Route exact path="/condition" element={<Condition />} />
          <Route exact path="/privacy" element={<Privacy />} />
        </Routes>
      </BrowserRouter>
    </div>

      {/* <ConnectWallet/> */}
    </>
  );
}

export default App;