import React from 'react'
import { Link } from 'react-router-dom'
import logo from "../img/logo.png"
import Footer from './Footer'
const Condition = () => {
    return (
        <div className='contentPage'> <header className="headerLink">
            <div className="container">
                <div className="row align-items-center">
                    <div className="col-12 text-center">
                        <Link to="/"><img src={logo} alt="logo" className="instatoken_logo" /></Link>
                    </div>
                </div>
            </div>
        </header>
            <section className="sectionLink">
                <div className="container">
                    <div className="pt-2 pt-lg-5 pb-5 pl-5 pr-5">
                        <h1>Terms and Conditions for INSTATOKEN</h1>

                        <p>Welcome to INSTATOKEN. By using our platform, you agree to comply with the following terms and conditions.</p>

                        <h2>Services</h2>

                        <p>INSTATOKEN provides a decentralized token exchange service where users can send native tokens to specific smart contract addresses and receive desired tokens in return, minus a 1% fee.</p>

                        <h2>User Responsibilities</h2>

                        <ul>
                            <li><b>Accurate Information:</b> You agree to provide accurate and current information when using our services.</li>
                            <li><b>Compliance:</b> You are responsible for complying with all applicable laws and regulations regarding cryptocurrency transactions.</li>
                            <li><b>Security:</b> You are responsible for securing your wallet and private keys. INSTATOKEN is not liable for any loss resulting from unauthorized access to your wallet.</li>
                        </ul>
                        <h2>Prohibited Activities</h2>
                        <ul>
                            <li><b>Fraud:</b> Engaging in any fraudulent activity is strictly prohibited.</li>
                            <li><b>Illegal Use:</b> Using our platform for any illegal purpose is not allowed.</li>
                        </ul>
                        <h2>Liability</h2>

                        <p>INSTATOKEN is not liable for any direct, indirect, incidental, or consequential damages arising from the use of our services. All transactions are final, and we do not provide refunds.</p>

                        <h2>Modifications</h2>

                        <p>We reserve the right to modify these terms at any time. Changes will be posted on this page, and continued use of our services constitutes acceptance of the updated terms.</p>

                    </div>
                </div>
            </section>
            <Footer />
        </div>
    )
}

export default Condition