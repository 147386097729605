import { combineReducers } from "redux";
import {
  Get_user_token_list,
  Token_by_influencer
  

} from "./Reducer/useReducer";

const rootReducer = combineReducers({
  Get_user_token_list,
  Token_by_influencer

 
});
export default rootReducer;
