import { toast } from 'react-toastify';

const token= localStorage.getItem("token")
// console.log(token, "token");

export function Gettokendata() {
  return async (dispatch) => {
    try {
      var response = await fetch(
        "/api/v1/instatoken_api.php",
        {
          method: "GET",
          headers: { "Content-Type": "application/json", Authorization:"Bearer INSTOKEN22062024" },
        }
      );

      if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
      }

      var res_data = await response.json();

      var return_response = {
        type: "GET_TOKEN_LIST",
        payload: res_data,
      };
      dispatch(return_response);
    } catch (error) {
      // Handle any errors that occurred during the fetch
      console.error("Error during fetch:", error);

      // Toast notification for the error
      const notify = () =>
        toast.error(`${error.message} , Something went wrong!`);
      notify();
    }
  };
}

export function Tokeninfluencer(formData) {
  // console.log(formData,"formData");
  return async (dispatch) => {
 
    try {
      var response = await fetch("/api/v1/instatoken_api.php", {
        method: "POST",
        headers: { "Content-Type": "application/json", Authorization: "Bearer INSTOKEN22062024" },
        body: JSON.stringify(formData),
      });

      if (!response.ok) {
        // Handle non-successful HTTP responses
        throw new Error(`HTTP error! Status: ${response.status}`);
      }

      var res_data = await response.json();
      // console.log(res_data,"res_data");
      var return_response = {
        type: "TOKENINFLUENCER_DATA",
        payload: res_data,
      };
      // console.log(return_response,"return_response");
      dispatch(return_response);
    } catch (error) {
      // Handle any errors that occurred during the fetch
      console.error("Error during login:", error);
      // Toast notification for the error
      const notify = () =>
         toast.error(`${error.message} , Something went wrong!`);
      notify();
    }
  };
}




export function UserRegister(formData, setProccessing) {
  setProccessing(true);
  return async (dispatch) => {
    try {
      var response = await fetch("http://161.97.146.42:5200/waSend/v1/admin/addAdminUser", {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify(formData),
      });

      if (!response.ok) {
        // Handle non-successful HTTP responses
        throw new Error(`HTTP error! Status: ${response.status}`);
      }

      var res_data = await response.json();
      // console.log(res_data,"res_data");
      setProccessing(false);
      var return_response = {
        type: "REG_DATA",
        payload: res_data,
      };
      dispatch(return_response);
    } catch (error) {
      // Handle any errors that occurred during the fetch
      console.error("Error during login:", error);
      setProccessing(false);
      // Toast notification for the error
      const notify = () =>
         toast.error(`${error.message} , Something went wrong!`);
      notify();
    }
  };
}
export function Useraddplan(formData, setProccessing) {
  setProccessing(true);
  return async (dispatch) => {
    try {
      var response = await fetch("http://161.97.146.42:5200/waSend/v1/admin/createPlan", {
        method: "POST",
        headers: { "Content-Type": "application/json", authorization: token },
        body: JSON.stringify(formData),
      });

      if (!response.ok) {
        // Handle non-successful HTTP responses
        throw new Error(`HTTP error! Status: ${response.status}`);
      }

      var res_data = await response.json();
      // console.log(res_data,"res_data");
      setProccessing(false);
      var return_response = {
        type: "ADD_PLAN_DATA",
        payload: res_data,
      };
      dispatch(return_response);
    } catch (error) {
      // Handle any errors that occurred during the fetch
      console.error("Error during login:", error);
      setProccessing(false);
      // Toast notification for the error
      const notify = () =>
        toast.error(`${error.message} , Something went wrong!`);
      notify();
    }
  };
}


export function EditgetPlandata(id) {
  return async (dispatch) => {
    try {
      var response = await fetch(
        `http://161.97.146.42:5200/waSend/v1/admin/retrievePlan/${id}`,
        {
          method: "GET",
          headers: { "Content-Type": "application/json", authorization: token },
        }
      );

      if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
      }

      var res_data = await response.json();

      var return_response = {
        type: "EDIT_GET_PLAN",
        payload: res_data,
      };
      dispatch(return_response);
    } catch (error) {
      // Handle any errors that occurred during the fetch
      console.error("Error during fetch:", error);

      // Toast notification for the error
      const notify = () =>
        toast.error(`${error.message} , Something went wrong!`);
      notify();
    }
  };
}

export function DeletePlandata(id) {
  return async (dispatch) => {
    try {
      var response = await fetch(
        `http://161.97.146.42:5200/waSend/v1/admin/deletePlan/${id}`,
        {
          method: "GET",
          headers: { "Content-Type": "application/json", authorization: token },
        }
      );

      if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
      }

      var res_data = await response.json();

      var return_response = {
        type: "DELETE_PLAN",
        payload: res_data,
      };
      dispatch(return_response);
    } catch (error) {
      // Handle any errors that occurred during the fetch
      console.error("Error during fetch:", error);

      // Toast notification for the error
      const notify = () =>
        toast.error(`${error.message} , Something went wrong!`);
      notify();
    }
  };
}

