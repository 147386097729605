import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import logo from "../img/logo.png";
import ethereum from "../img/ethereum.png";
import protocol from "../img/protocol.png";
import binance from "../img/binance.png";
import polygon from "../img/polygon.png";
import avalanche from "../img/avalanche.png";
import solana from "../img/solona.png";
import QRCode from "qrcode.react";
import Footer from "./Footer";
import { connect } from "react-redux";
import minilogo from '../img/favicon.png'
import { Gettokendata } from "../components/Store/Action/useFetchData";

const Home = ({ dispatch, res_get_token }) => {
  const [options, setOptions] = useState({ name: "Ethereum", image: ethereum });
  const [searchInput, setSearchInput] = useState("");
  const [filteredResults, setFilteredResults] = useState([]);
  const [selectedCoins, setSelectedCoins] = useState();

  const [searchResults, setSearchResults] = useState([]);
  useEffect(() => {
    dispatch(Gettokendata());
  }, [dispatch]);
  console.log(res_get_token, "fffffff");

  useEffect(() => {
    if (res_get_token?.loading === false && res_get_token?.data?.length > 0) {
      const tokenData = res_get_token.data.map((token) => ({
        token: token.token_name,
        symbol: token.token_symbol,
        address: token.token_swap_contract_address,
        chain: token.chain_name,
      }));

      setSearchResults(tokenData);
    }
  }, [res_get_token]);

  console.log(res_get_token, "res_get_token");
  console.log(searchResults, "searchResults");
  const [showCheckmark, setShowCheckmark] = useState(false);
  const copyToClipboard = () => {
    const input = document.getElementById("input-control-result");
    if (input) {
      input.select();
      document.execCommand("copy");
      setShowCheckmark(true);
      setTimeout(() => {
        setShowCheckmark(false);
        input.setSelectionRange(0, 0);
        input.blur();
      }, 1500);
    }
  };
  const handleNetworkChange = (network) => {
    setOptions({ ...options, name: network });
    console.log(searchInput, "searchInput");
    if (options.name !== network) {
      setSelectedCoins();
    }
    filterResults(network, searchInput);
  };
  const handleInputChange = (event) => {
    const search = event.target.value;
    setSearchInput(search);
    setSelectedCoins();
    filterResults(options.name, search);
  };
  const [FoundChains, setFoundInChains] = useState("");
  const filterResults = (chain, search) => {
    if (search.trim() === "") {
      document.getElementById("noDots").style.display = "none";
      setFilteredResults();
      return;
    }
    // console.log(chain, "chain");
    // console.log(search, "search");
    const temp = searchResults.filter((result) => {
      // console.log(result?.chain === chain &&
      //   (result.token.toLowerCase().includes(search.toLowerCase()) ||
      //     result.symbol.toLowerCase().includes(search.toLowerCase())), "ssss");
      return (
        result?.chain === chain &&
        (result.token.toLowerCase().includes(search.toLowerCase()) ||
          result.symbol.toLowerCase().includes(search.toLowerCase()))
      );
    });
    // console.log(temp, "temp");
    setFilteredResults(temp);
    if (temp) {
      document.getElementById("noDots").style.display = "block";
    }
    const chainsFoundIn = searchResults.filter((result) => {
      return (
        result.token.toLowerCase().includes(search.toLowerCase()) ||
        result.symbol.toLowerCase().includes(search.toLowerCase())
      );
    });

    console.log(chainsFoundIn, "chainsFoundIn");
    setFoundInChains(chainsFoundIn);
  };

  const handleOnClick = (result) => {
    setSelectedCoins(result);
    setSearchInput(result.token);
    setFilteredResults([]);
    setFoundInChains("");
    setOptions({
      name: result.chain,
      image:
        result.chain === "Ethereum"
          ? ethereum
          : result.chain === "BaseTestnet"
            ? protocol
            : result.chain === "Binance"
              ? binance
              : result.chain === "Polygon"
                ? polygon
                : result.chain === "Avalanche"
                  ? avalanche
                  : "",
    });
    document.getElementById("noDots").style.display = "none";
  };
  return (
    <>
      <header>
        <div className="container">
          <div className="row align-items-center">
            <div className="col-md-6 col-12 text-md-start text-center">
              <Link to="/">
                {" "}
                <img src={logo} alt="logo" className="instatoken_logo" />
              </Link>
            </div>
            <div className="col-md-6 col-12 btnHeader text-md-end text-center mt-md-0 mt-5">
              <Link to="/wallet" className="btnContract">
                Create Your Smart Contract
              </Link>
            </div>
          </div>
        </div>
      </header>
      <section className="content-section">
        <div className="container">
          <div className="row">
            <div className="offset-lg-1 col-lg-10">
              <div className="searchBarSection text-center">
                <div className="searchSection">
                  <div className="input-group flex-md-nowrap">
                    <span className="input-group-text" id="addon-wrapping">
                      <i className="fa-solid fa-magnifying-glass"></i>
                    </span>
                    <input
                      type="text"
                      id="searchBar"
                      autoComplete="off"
                      className="form-control inputText"
                      placeholder="Search tokens like Uniswap, Shiba, Chainlink..."
                      aria-describedby="addon-wrapping"
                      value={searchInput}
                      onChange={handleInputChange}
                    />
                    <button
                      className="btn btn-outline-secondary dropdown-toggle btndropDown border-color"
                      type="button"
                      data-bs-toggle="dropdown"
                      aria-expanded="false"
                    >
                      <div className="d-flex align-items-center">
                        <div>
                          <img
                            src={options.image}
                            alt="dropdown"
                            className="image_searchManu"
                            width="42px"
                          />
                        </div>
                        <div className="btnArrow">{options.name}</div>
                      </div>
                    </button>
                    <ul className="dropdown-menu dropdown-menu-end dropDownMenu">
                      <li
                        className="dropdown-item"
                        onClick={() => {
                          handleNetworkChange("Ethereum");
                          setOptions({ name: "Ethereum", image: ethereum });
                        }}
                      >
                        <div className="d-flex">
                          <div>
                            <img
                              src={ethereum}
                              alt=""
                              className="image_searchManu"
                            />
                          </div>
                          <div className="textColordrp">
                            Ethereum
                            <br />
                            <span className="drpSpanText">ETH</span>
                          </div>
                        </div>
                      </li>
                      <li
                        className="dropdown-item"
                        onClick={() => {
                          handleNetworkChange("BaseTestnet");
                          setOptions({ name: "BaseTestnet", image: protocol });
                        }}
                      >
                        <div className="d-flex">
                          <div>
                            <img
                              src={protocol}
                              alt=""
                              className="image_searchManu"
                            />
                          </div>
                          <div className="textColordrp">
                            Base Protocol
                            <br />
                            <span className="drpSpanText">ETH</span>
                          </div>
                        </div>
                      </li>
                      <li
                        className="dropdown-item"
                        onClick={() => {
                          handleNetworkChange("Binance");
                          setOptions({ name: "Binance", image: binance });
                        }}
                      >
                        <div className="d-flex">
                          <div>
                            <img
                              src={binance}
                              alt=""
                              className="image_searchManu"
                            />
                          </div>
                          <div className="textColordrp">
                            Binance
                            <br />
                            <span className="drpSpanText">ETH</span>
                          </div>
                        </div>
                      </li>
                      <li
                        className="dropdown-item"
                        onClick={() => {
                          handleNetworkChange("Polygon");
                          setOptions({ name: "Polygon", image: polygon });
                        }}
                      >
                        <div className="d-flex">
                          <div>
                            <img
                              src={polygon}
                              alt=""
                              className="image_searchManu"
                            />
                          </div>
                          <div className="textColordrp">
                            Polygon
                            <br />
                            <span className="drpSpanText">ETH</span>
                          </div>
                        </div>
                      </li>
                      <li
                        className="dropdown-item"
                        onClick={() => {
                          handleNetworkChange("Avalanche");
                          setOptions({ name: "Avalanche", image: avalanche });
                        }}
                      >
                        <div className="d-flex">
                          <div>
                            <img
                              src={avalanche}
                              alt=""
                              className="image_searchManu"
                            />
                          </div>
                          <div className="textColordrp">
                            Avalanche
                            <br />
                            <span className="drpSpanText">ETH</span>
                          </div>
                        </div>
                      </li>
                      <li
                        className="dropdown-item border-0"
                        onClick={() => {
                          handleNetworkChange("Solana");
                          setOptions({ name: "Solana", image: solana });
                        }}
                      >
                        <div className="d-flex">
                          <div>
                            <img
                              style={{ width: "44px" }}
                              src={solana}
                              alt=""
                              className="image_searchManu"
                            />
                          </div>
                          <div className="textColordrp">
                            Solana
                            <br />
                            <span className="drpSpanText">ETH</span>
                          </div>
                        </div>
                      </li>
                    </ul>
                  </div>
                  <ul id="noDots" className="search-result">
                    {filteredResults?.length ? (
                      filteredResults.map((result, index) => (
                        <li
                          key={index}
                          className="searchMenu  scrollbar-thin scroll-main"
                          onClick={() => handleOnClick(result)}
                        >
                          <div className="d-flex justify-content-between">
                            <div className="d-flex">
                              <div>
                                <img
                                  src={result?.token_icon ? result?.token_icon : minilogo}
                                  alt=""
                                  width="40px"
                                  style={{ borderRadius: "50%" }}
                                  className="image_searchManu mt-2"
                                />
                              </div>
                            <div className="textColordrp">
                              {result.token}
                              <br />
                              <span className="drpSpanText">
                                {result.address}
                              </span>
                            </div>
                            </div>
                            <div className="d-flex align-items-center mx-3">
                              <div>
                                <img
                                  src={options.image}
                                  alt="dropdown"
                                  className="image_searchManu"
                                  width="32px"
                                />
                              </div>
                              <div className="btnArrow">{options.name}</div>
                            </div>
                          </div>
                        </li>
                      ))
                    ) : (
                      <li
                        className="searchMenu border-0"
                        style={{ padding: "15px 0 " }}
                      >
                        <div className="d-flex justify-content-start">
                          <p
                            className=" text-center mb-0"
                            style={{ fontSize: "18px " }}
                          >
                            We couldn't find any results for token you search: "
                            {searchInput}".
                          </p>
                        </div>
                      </li>
                    )}

                    {!filteredResults?.length &&
                      searchInput !== "" &&
                      FoundChains.length > 0 && (
                        <p className=" p-record m-0 text-start pt-0">
                          Explore your token in another chains:
                        </p>
                      )}
                    {!filteredResults?.length &&
                      searchInput !== "" &&
                      FoundChains.length > 0 &&
                      FoundChains.map((result, index) => (
                        <li
                          key={index}
                          className="searchMenu  scrollbar-thin scroll-main"
                          onClick={() => handleOnClick(result)}
                        >
                          <div className="d-flex justify-content-between">
                            <div className="d-flex">
                              <div>
                                <img
                                  src={result?.token_icon ? result?.token_icon : minilogo}
                                  alt=""
                                  width="40px"
                                  style={{ borderRadius: "50%" }}
                                  className="image_searchManu mt-2"
                                />
                              </div>
                              <div className="textColordrp">
                                {result.token}
                                <br />
                                <span className="drpSpanText">
                                  {result.address}
                                </span>
                              </div>
                            </div>
                            <div className="d-flex align-items-center mx-3">
                              <div>
                                <img
                                  src={
                                    result.chain === "Ethereum"
                                      ? ethereum
                                      : result.chain === "BaseTestnet"
                                        ? protocol
                                        : result.chain === "Binance"
                                          ? binance
                                          : result.chain === "Polygon"
                                            ? polygon
                                            : result.chain === "Avalanche"
                                              ? avalanche
                                              : ""
                                  }
                                  // alt="dropdown"
                                  className="image_searchManu"
                                  width="32px"
                                />
                              </div>
                              <div className="btnArrow">
                                {result.chain === "Ethereum"
                                  ? "Ethereum"
                                  : result.chain === "BaseTestnet"
                                    ? "BaseTestnet"
                                    : result.chain === "Binance"
                                      ? "Binance"
                                      : result.chain === "Polygon"
                                        ? "Polygon"
                                        : result.chain === "Avalanche"
                                          ? "Avalanche"
                                          : ""}
                              </div>
                            </div>
                          </div>
                        </li>
                      ))}
                  </ul>
                </div>
                <div className="">
                  <p className="searchText">
                    more than 1000+ tokens available for swap
                  </p>
                </div>
              </div>
            </div>
          </div>
          {selectedCoins && (
            <div className="row sectionResult">
              <div className="offset-md-2 col-md-8">
                <div className="input-group input-group-result">
                  <input
                    id="input-control-result"
                    type="text"
                    className="form-control input-control-result"
                    readOnly
                    value={selectedCoins.address}
                    onClick={copyToClipboard}
                  />
                  <span className="input-group-text">
                    {showCheckmark ? (
                      <i className="fa-solid fa-check"></i>
                    ) : (
                      <i
                        className="fa-solid fa-copy cursor-pointer"
                        onClick={copyToClipboard}
                      ></i>
                    )}
                  </span>
                  <span className="input-group-text qr-code-icon spanText">
                    <i className="fa-solid fa-qrcode cursor-pointer"></i>
                    <div className="qr-code">
                      <QRCode value={selectedCoins.address} size={120} />
                    </div>
                  </span>
                </div>
              </div>
            </div>
          )}
        </div>
      </section>
      <Footer />
    </>
  );
};

const mapStateToProps = (state) => ({
  res_get_token: state.Get_user_token_list,
});

export default connect(mapStateToProps)(Home);