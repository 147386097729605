import React, { useEffect, useRef } from 'react'

const ComingSoon = ({ setShowComingSoon, showComingsoon, comingSoonValue }) => {
    const modalRef = useRef();
    useEffect(() => {
        const handleClickOutside = (event) => {
            if (modalRef.current && !modalRef.current.contains(event.target)) {
                setShowComingSoon(false);
            }
        };

        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, [setShowComingSoon]);
    return (
        <>

            <div className={`modal ${showComingsoon && 'show'}`} id="myModal" style={{ display: showComingsoon ? "block" : "none", background: showComingsoon ? "#07070787" : "transparant" }} >
                <div className="modal-dialog modal-dialog-centered">
                    <div class="modal-header">
                    </div>
                    <div className="modal-content position-relative" ref={modalRef}>
                        <div className='row justify-content-end'>

                            <button type="button" class="btn-close " onClick={() => setShowComingSoon(false)}></button>
                        </div>

                        <div className="modal-body">
                            <h3 className="modal-title text-center fw-bold mt-3">COMING SOON</h3>
                            <p className='text-center'>{comingSoonValue}</p>

                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default ComingSoon
