import React from 'react'

import logo from "../img/logo.png"
import { Link } from 'react-router-dom'
const Footer = () => {
  return (
    <>
       <footer  className="footerLink">
        <div className="container">
            <div className="row flex-column flex-lg-row text-center align-items-center">
                <div className="col-lg-3">
                <Link to="/"><img src={logo} alt="logo" height="20px"/></Link>
                </div>
                <div className="col-lg-9">
                    <ul className="footerMenu justify-content-center justify-content-lg-end mt-lg-0 mt-3 flex-column flex-lg-row">
                        <li className="textFooter">
                            <Link to="/about">About</Link>
                        </li>
                        <li className="textFooter">
                            <Link to="/privacy">Privacy Policy</Link>
                        </li>
                        <li className="textFooter">
                            <Link to="/condition">Terms & Condition</Link>
                        </li>
                        {/* <li className="textFooter">
                            <Link to="/">Settings</Link>
                        </li> */}
                    </ul>
                </div>
            </div>
        </div>
    </footer>
    </>
  )
}

export default Footer