import React, { useEffect, useState, useRef } from "react";
import logo from "../img/logo.png";
import ethereum from "../img/ethereum.png";
import protocol from "../img/protocol.png";
import binance from "../img/binance.png";
import polygon from "../img/polygon.png";
import avalanche from "../img/avalanche.png";
import solona from "../img/solona.png";
import Footer from "./Footer";
import ConnectModel from "./ConnectModel";
import BASE_ABI from "./Abi/BASEABI.json";
import BSC_ABI from "./Abi/BSCABI.json";
import Web3 from "web3";
import { ethers } from "ethers";
import ComingSoon from "./ComingSoon";
import DotDotDotLoader from "./DotDotDotLoader";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { PublicKey } from '@solana/web3.js';
import QRCode from 'qrcode.react';
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import { Tokeninfluencer } from "../components/Store/Action/useFetchData";

const SwapToken = ({ dispatch, res_post_token }) => {
  const [showModel, setShowModel] = useState(false);
  const [account, setAccount] = useState(null);
  const [accountAdd, setAccountAdd] = useState(null)
  const [contract, setContract] = useState(null);
  const [implementContract, setImplementContract] = useState(null);
  const [deployedContractAddress, setDeployedContractAddress] = useState(null);
  const [tokenAddress, setTokenAddress] = useState(null);
  const [web3, setWeb3] = useState(null);
  const [contactAddress, setContractAddress] = useState("");
  const [contactAbi, setContractAbi] = useState("");
  const [currentChain, setCurrentChain] = useState("");
  const [connectedWalletType, setConnectedWalletType] = useState("");
  const [tokenToSwap, setTokenToSwap] = useState("");
  const [network, setNetwork] = useState("Ethereum");
  const [tokenfiled, setTokenfield] = useState({

  })
  const [contractData, setContractData] = useState([
    //   {
    //   contract_address: "ox111313212312312312131312",
    //   name: "",
    //   symbol: "",
    //   tokenAddressTemp: "ox111313212312312312131312",
    // }
  ]);

  const [options, setOptions] = useState({ name: "Ethereum", image: ethereum });
  const [loading, setLoading] = useState(false);
  const [firstFetch, setFirstFetch] = useState(true);
  const [data, setData] = useState({});
  // console.log(firstFetch, 'firstfetch');
  const tooltipRef = useRef(null);
  // console.log(account, "appacount");

  const [showComingsoon, setShowComingSoon] = useState(false);
  const [comingSoonValue, setComingSoonValue] = useState("");

  const BASE_ADDRESS = process.env.REACT_APP_BASE_CHAIN_CONTRACT_ADDRESS;
  const BSC_ADDRESS = process.env.REACT_APP_BSC_CHAIN_CONTRACT_ADDRESS;

  //   const BASE_ADDRESS = process.env.BASE_CHAIN_CONTRACT_ADDRESS;
  // const BSC_ADDRESS = process.env.BSC_CHAIN_CONTRACT_ADDRESS;

  // console.log(BASE_ADDRESS, 'BASE_ADDRESS');
  // console.log(BSC_ADDRESS, 'BSC_ADDRESS');


  // console.log(res_post_token, "res_post_token");

  const [showCheckmark, setShowCheckmark] = useState(false);
  const copyToClipboard = (address, index) => {
    navigator.clipboard.writeText(address).then(() => {
      setShowCheckmark(index);
      // Optionally, reset the copied state after a delay
      setTimeout(() => {
        setShowCheckmark(null);
      }, 2000); // Change the delay as needed
    });
  };


  const handleTokenToSwapChange = (event) => {
    setTokenToSwap(event.target.value);

  };

  const handleNetworkChange = (network) => {
    setNetwork(network);
    if (network === "Bscmainnet") {
      // console.log("Bscmainnet");
      setContractAddress(BSC_ADDRESS);
      setContractAbi(BSC_ABI);
      setData({ ...data, chain_name: network })
      fetchData(BSC_ADDRESS, BSC_ABI);
    } else if (network === "BaseTestnet") {
      // console.log("BaseTestnet");
      setContractAddress(BASE_ADDRESS);
      setContractAbi(BASE_ABI);
      fetchData(BASE_ADDRESS, BASE_ABI);
      setData({ ...data, chain_name: network })
    }
  };


  useEffect(() => {
    if (window.ethereum) {
      const web3Instance = new Web3(window.ethereum);
      setWeb3(web3Instance);
    } else if (window.web3) {
      const web3Instance = new Web3(window.web3.currentProvider);
      setWeb3(web3Instance);
    } else {

      const notify1 = () => toast.error('Non-Ethereum browser detected. You should consider trying MetaMask!', {
        // duration: 45000,
        style: {
          border: '1px solid rgb(244 67 54 / 40%)',
          padding: '10px 15px ',
          color: '#f44336',
          fontWeight: '600',
          backgroundColor: 'rgb(255, 204, 203)',
          boxShadow: '0px 0px 20px 0px rgba(255, 255, 255, 0.1)'

        },
        iconTheme: {
          primary: '#f44336',
          secondary: '#fff',

        },
        autoClose: 2000,



      });
      notify1()


    }
  }, []);

  const connectMetaMask = async (connectedType) => {
    setConnectedWalletType(connectedType);
    // console.log(connectedType, 'cureeeeeeeeeee');

    if (connectedType === "metaMask") {
      // console.log(window.ethereum);
      if (window.ethereum) {
        try {
          const web3Instance = new Web3(window.ethereum);
          setWeb3(web3Instance);
          await window.ethereum.request({ method: "eth_requestAccounts" });
          const accounts = await web3.eth.getAccounts();
          // console.log(accounts, "accountsaccounts");
          setAccount(accounts[0]);
          setAccountAdd(accounts[0])
        } catch (error) {
          console.error("User denied account access");
        }
      } else if (window.web3) {
        const web3Instance = new Web3(window.web3.currentProvider);
        setWeb3(web3Instance);
      } else {
        const notify1 = () => toast.error('Non-Ethereum browser detected. You should consider trying MetaMask!', {
          // duration: 45000,
          style: {
            border: '1px solid rgb(244 67 54 / 40%)',
            padding: '10px 15px ',
            color: '#f44336',
            fontWeight: '600',
            backgroundColor: 'rgb(255, 204, 203)',
            boxShadow: '0px 0px 20px 0px rgba(255, 255, 255, 0.1)'

          },
          iconTheme: {
            primary: '#f44336',
            secondary: '#fff',

          },
          autoClose: 2000,



        });
        notify1()
      }
    }
  };
  // console.log(contractData, "setContractData");
  // --------------------------------------------------------------------------------------------

  // useEffect(() => {
  const fetchData = async (contact_address, contact_abi) => {
    // console.log(web3, "we3ccc");
    // console.log(contact_abi, "contact_abixccc");
    // console.log(contact_address, "contact_addressdfdf");

    if (web3 && contact_abi && contact_address) {
      // console.log(contact_abi, "abiiiiiiiiii");
      // console.log(contact_address, "cccccccccccccaddresssssssssss");
      try {
        const contractInstance = new web3.eth.Contract(
          contact_abi,
          contact_address
        );
        setContract(contractInstance);
        // console.log(contractInstance, "contractInstance");
        // if (contractInstance && account) {
        //   console.log("hithere");
        //   const deployedAddress = await contractInstance.methods.deployedContract(account).call();
        //   setDeployedContractAddress(deployedAddress);
        //   console.log('Deployed contract address:', deployedAddress);
        // } else {
        //   console.log('Contract or account not available');
        // }
      } catch (error) {
        console.error("Error creating contract instance:", error);
      }
    } else {

      const notify1 = () => toast.error('contactAbi not available.', {
        // duration: 45000,
        style: {
          border: '1px solid rgb(244 67 54 / 40%)',
          padding: '10px 15px ',
          color: '#f44336',
          fontWeight: '600',
          backgroundColor: 'rgb(255, 204, 203)',
          boxShadow: '0px 0px 20px 0px rgba(255, 255, 255, 0.1)'

        },
        iconTheme: {
          primary: '#f44336',
          secondary: '#fff',

        },
        autoClose: 2000,



      });
      notify1()




    }
  };

  // }, [web3, contactAbi, contactAddress, account]);
// console.log(contactAbi,"contactAbi");
  const handleCreateContract = async (e) => {
    e.preventDefault();
    setLoading(true);


    if (!account) {
      const notify1 = () => toast.error('Please connect with wallet!', {
        // duration: 45000,
        style: {
          border: '1px solid rgb(244 67 54 / 40%)',
          padding: '10px 15px ',
          color: '#f44336',
          fontWeight: '600',
          backgroundColor: 'rgb(255, 204, 203)',
          boxShadow: '0px 0px 20px 0px rgba(255, 255, 255, 0.1)'

        },
        iconTheme: {
          primary: '#f44336',
          secondary: '#fff',

        },
        autoClose: 2000,



      });
      notify1()


      setLoading(false);
      return;
    }

    if (
      network === "Ethereum" ||
      network === "Polygon" ||
      network === "Avalanche" ||
      network === "Solana"
    ) {
      // console.log("sdsdsds");
      setShowComingSoon(true);
      setComingSoonValue(network);
      setLoading(false);
      return;
    } else {
      console.log("Please select a valid network");
    }

    if (!tokenToSwap) {
      const notify1 = () => toast.error('Please enter token contract address!', {
        // duration: 45000,
        style: {
          border: '1px solid rgb(244 67 54 / 40%)',
          padding: '10px 15px ',
          color: '#f44336',
          fontWeight: '600',
          backgroundColor: 'rgb(255, 204, 203)',
          boxShadow: '0px 0px 20px 0px rgba(255, 255, 255, 0.1)'

        },
        iconTheme: {
          primary: '#f44336',
          secondary: '#fff',

        },
        autoClose: 2000,



      });
      notify1()



      console.log("Please enter token contract address");
      setLoading(false);
      return;
    }
    // console.log(tokenToSwap, 'gtokenToSwap');
    if (tokenToSwap) {
      if (options.name === "Solona") {
        const publicKey = new PublicKey(tokenToSwap);
        const validate = PublicKey.isOnCurve(publicKey.toBuffer());
        if (!validate) {
          const notify1 = () => toast.error('Please enter valid token contract address!', {
            // duration: 45000,
            style: {
              border: '1px solid rgb(244 67 54 / 40%)',
              padding: '10px 15px ',
              color: '#f44336',
              fontWeight: '600',
              backgroundColor: 'rgb(255, 204, 203)',
              boxShadow: '0px 0px 20px 0px rgba(255, 255, 255, 0.1)'

            },
            iconTheme: {
              primary: '#f44336',
              secondary: '#fff',

            },
            autoClose: 2000,



          });
          notify1()
          setLoading(false);
          return
        }

      } else {
        const validate = ethers.isAddress(tokenToSwap)
        // console.log(validate, 'validate');
        if (!validate) {
          const notify1 = () => toast.error('Please enter valid token contract address!', {
            // duration: 45000,
            style: {
              border: '1px solid rgb(244 67 54 / 40%)',
              padding: '10px 15px ',
              color: '#f44336',
              fontWeight: '600',
              backgroundColor: 'rgb(255, 204, 203)',
              boxShadow: '0px 0px 20px 0px rgba(255, 255, 255, 0.1)'

            },
            iconTheme: {
              primary: '#f44336',
              secondary: '#fff',

            },
            autoClose: 2000,



          });
          notify1()
          setLoading(false);
          return
        }

      }

    }


    if (account) {

      const network_chainId = await window.ethereum.request({ method: 'eth_chainId' });
      var currentNetworkId = network_chainId;
      // console.log(currentNetworkId, 'cureeeeeeeeeee');

      if (currentNetworkId != options.chainId) {
        try {
          await window.ethereum.request({
            method: 'wallet_switchEthereumChain',
            params: [{ chainId: options.chainId }], // Convert to hex
          });
        } catch (error) {
          console.log(error, 'erorrrororor');
          const notify1 = () => toast.error('The selected chain is not available in your wallet. Please add the chain.', {
            // duration: 45000,
            style: {
              border: '1px solid rgb(244 67 54 / 40%)',
              padding: '10px 15px ',
              color: '#f44336',
              fontWeight: '600',
              backgroundColor: 'rgb(255, 204, 203)',
              boxShadow: '0px 0px 20px 0px rgba(255, 255, 255, 0.1)'

            },
            iconTheme: {
              primary: '#f44336',
              secondary: '#fff',

            },
            autoClose: 2000,



          });
          notify1()
          setLoading(false);
          return

        }
      }
      // console.log();


    }

    var obj;
    // console.log(contract, "contract");
    // console.log(account, "account");
    // console.log(tokenToSwap, "tokenToSwap");
    // console.log(network, "network");



    if (contract && account && tokenToSwap && network) {
      try {
        // Create contract
        // obj()
        obj = {
          token_contract_address: tokenToSwap, fee_wallet_address: account, wallet_address: account, token_data: "2", chain_name: network === "Bscmainnet"?"Bsc Main Net":network
        }
        // setData({ ...data, token_contract_address: tokenToSwap, fee_wallet_address: account, wallet_address: account, token_data:"2" })
        // console.log('====================================');
        // console.log("gaurang1");
        // console.log('====================================');
        await contract.methods
          .createContract(account, tokenToSwap)
          .send({ from: account });
        console.log("Contract created successfully");

        // After creating, fetch deployed contract address
        await handleGetDeployedContract(obj);
        setLoading(false);
      } catch (error) {
        setLoading(false);
        console.error("Error creating contract:", error);
      }
    } else {
      setLoading(false);
      console.log("Contract, account, token to swap, or network is missing");
    }
  };

  const handleGetDeployedContract = async (obj) => {
    if (contract && account) {
      try {
        const deployedAddress = await contract.methods
          .deployedContract(account)
          .call();
        setDeployedContractAddress(deployedAddress);

        if (deployedAddress) {
          const contracts = [];

          if (!window.ethereum) {
            console.error("MetaMask is not installed");
            setLoading(false);
            return;
          }

          const provider = new ethers.BrowserProvider(window.ethereum);

          for (const address of deployedAddress) {
            contracts.push({
              contract_address: address.contractAddress,
              name: "",
              symbol: "",
              tokenAddressTemp: address.tokenContract,

            });
          }

          // console.log("firstimemmemmemeememe");

          setContractData(contracts);
          // console.log('====================================');
          // console.log(contracts, "contracts");
          // console.log('====================================');

          const lastIndex = contracts.length - 1
          // console.log('====================================');
          // console.log("lastindex", lastIndex);
          // console.log('====================================');
          const contractPromises = contracts.map(async (data, index) => {
            console.log(data,"datadatadatadata");
            const delay = (ms) =>
              new Promise((resolve) => setTimeout(resolve, ms));
            await delay(index * 1000);

            try {
              const tokenContract = new ethers.Contract(
                data.tokenAddressTemp,
                [
                  "function name() view returns (string)",
                  "function symbol() view returns (string)",
                ],
                provider
              );

              let name = "";
              let symbol = "";

              try {
                name = await tokenContract.name();
                symbol = await tokenContract.symbol();
                // console.log('====================================');
                // // console.log(tokenContract,"tokenContract");
                // console.log('====================================');

              } catch (error) {
                console.error(
                  `Error fetching token info for contract ${data.contract_address}:`,
                  error
                );
              }

              if (lastIndex === index) {
                // const temp = {token_name: name, token_symbol: symbol, swap_contract_address :data.tokenAddressTemp}
                obj = { ...obj, token_name: name, token_symbol: symbol, swap_contract_address: data.contract_address }
                // setData({ ...data, token_name: name, token_symbol: symbol, swap_contract_address :data.tokenAddressTemp})
                // console.log('====================================');
                // console.log("gaurang2");
                // console.log('====================================');
              }


              setContractData((prevData) =>
                prevData.map((item) =>
                  item.contract_address === data.contract_address
                    ? { ...item, name, symbol }
                    : item
                )

              );
              // console.log(contractData, "setContractData");
              // console.log("secondtimeejnfjkndjfnjn");
              setFirstFetch(false);

            } catch (error) {
              console.error(
                `Error creating contract instance for ${data.contract_address}:`,
                error
              );
            }
          });

          await Promise.all(contractPromises);
          console.log('====================================');
          console.log(obj,"objjjjjjjjjjjjjjjj");
          console.log('====================================');
          dispatch(Tokeninfluencer(obj))
          const notify = () => toast.success('Contract create succesfully', {
            style: {
              border: '1px solid rgb(0 186 50 / 40%)',
              padding: '10px 15px ',
              color: '#2C6C3F',
              fontWeight: '600',
              backgroundColor: 'rgb(160, 214, 180)',
              boxShadow: '0px 0px 20px 0px rgba(255, 255, 255, 0.1)'
            },
            iconTheme: {
              primary: '#2C6C3F',
              secondary: '#fff',
            },
            autoClose: 2000,



          });
          notify()
        }
      } catch (error) {
        console.error("Error getting deployed contract:", error);
      }
    } else {
      const notify1 = () => toast.error('Contract or account not available!', {
        // duration: 45000,
        style: {
          border: '1px solid rgb(244 67 54 / 40%)',
          padding: '10px 15px ',
          color: '#f44336',
          fontWeight: '600',
          backgroundColor: 'rgb(255, 204, 203)',
          boxShadow: '0px 0px 20px 0px rgba(255, 255, 255, 0.1)'

        },
        iconTheme: {
          primary: '#f44336',
          secondary: '#fff',

        }, Close: 2000,



      });
      notify1()
      console.log("Contract or account not available");
    }
  };

  // console.log(
  //   contractData,
  //   "  const delay = (ms) => new Promise(resolve => setTimeout(resolve, ms));"
  // );
  // console.log(options, "options");
  // console.log(firstFetch, 'firstfetch');

  const truncate = (text, length) => {
    if (!text) return '';
    if (text.length <= length) return text;
    const start = text.substring(0, 4);
    const end = text.substring(text.length - 4, text.length);
    return `${start}...${end}`;
  };

  return (
    <>
      <ToastContainer
        position="top-center"
      // reverseOrder={false}

      />


      <header>
        <div className="container">
          <div className="row align-items-center">
            <div className="col-md-6 col-12 text-md-start text-center">
              <Link to="/">
                {" "}
                <img src={logo} alt="logo" className="instatoken_logo" />
              </Link>
            </div>
            <div className="col-md-6 col-12 btnHeader text-md-end text-center mt-md-0 mt-5">
              <button
                className="btnContract"
                // data-bs-toggle="modal" data-bs-target="#myModal"
                onClick={() => setShowModel(true)}
              >
                {accountAdd ? truncate(accountAdd, 6) : "Connect Wallet"}
                {accountAdd &&
                  <div className="btnHover">
                    {accountAdd}
                  </div>
                }
              </button>
            </div>
          </div>
        </div>
      </header>
      <section className="content-section">
        <div className="container">
          <div className="row">
            <div className="offset-lg-1 col-lg-10">
              <div className="searchBarSection text-center form-bg">
                <form action="" method="post">
                  <div className="row">
                    <div className="col-md-4 chainLabel">
                      <label htmlFor="chain">Chain</label>
                      <div className="input-group flex-nowrap drpWallet h-70">
                        <button
                          className="btn btn-outline-secondary dropdown-toggle btndropDown btnBorder w-100 "
                          type="button"
                          data-bs-toggle="dropdown"
                          aria-expanded="false"
                        >
                          <div className="d-flex align-items-center">
                            <div className="">
                              {options.name === "Solana" ? (
                                <img
                                  style={{ width: "44px" }}
                                  src={options.image}
                                  alt="dropdown"
                                  className="image_searchManu"
                                />
                              ) : (
                                <img
                                  src={options.image}
                                  alt="dropdown"
                                  className="image_searchManu"
                                />
                              )}
                            </div>
                            <div className="">{options.name}</div>
                          </div>
                        </button>
                        <ul className="dropdown-menu dropdown-menu-end dropDownMenu">
                          <li
                            className="dropdown-item"
                            onClick={() => {
                              handleNetworkChange("Ethereum");
                              setOptions({ name: "Ethereum", image: ethereum });
                            }}
                          >
                            <div className="d-flex">
                              <div className="">
                                <img
                                  src={ethereum}
                                  alt=""
                                  className="image_searchManu"
                                />
                              </div>
                              <div className="textColordrp">
                                Ethereum
                                <br />
                                <span className="drpSpanText">ETH</span>
                              </div>
                            </div>
                          </li>
                          <li
                            className="dropdown-item"
                            onClick={() => {
                              handleNetworkChange("BaseTestnet");
                              setOptions({
                                name: "BaseTestnet",
                                image: protocol,
                                chainId: "0x14a34"
                              });
                            }}
                          >
                            <div className="d-flex">
                              <div className="">
                                <img
                                  src={protocol}
                                  alt=""
                                  className="image_searchManu"
                                />
                              </div>
                              <div className="textColordrp">
                                Base Protocol
                                <br />
                                <span className="drpSpanText">ETH</span>
                              </div>
                            </div>
                          </li>
                          <li
                            className="dropdown-item"
                            onClick={() => {
                              handleNetworkChange("Bscmainnet");
                              setOptions({ name: "Binance", image: binance, chainId: "0x38" });
                            }}
                          >
                            <div className="d-flex">
                              <div className="">
                                <img
                                  src={binance}
                                  alt=""
                                  className="image_searchManu"
                                />
                              </div>
                              <div className="textColordrp">
                                Binance
                                <br />
                                <span className="drpSpanText">ETH</span>
                              </div>
                            </div>
                          </li>
                          <li
                            className="dropdown-item"
                            onClick={() => {
                              handleNetworkChange("Polygon");
                              setOptions({ name: "Polygon", image: polygon });
                            }}
                          >
                            <div className="d-flex">
                              <div className="">
                                <img
                                  src={polygon}
                                  alt=""
                                  className="image_searchManu"
                                />
                              </div>
                              <div className="textColordrp">
                                Polygon
                                <br />
                                <span className="drpSpanText">ETH</span>
                              </div>
                            </div>
                          </li>
                          <li
                            className="dropdown-item"
                            onClick={() => {
                              handleNetworkChange("Avalanche");
                              setOptions({
                                name: "Avalance",
                                image: avalanche,
                              });
                            }}
                          >
                            <div className="d-flex">
                              <div className="">
                                <img
                                  src={avalanche}
                                  alt=""
                                  className="image_searchManu"
                                />
                              </div>
                              <div className=" textColordrp">
                                Avalance
                                <br />
                                <span className="drpSpanText">ETH</span>
                              </div>
                            </div>
                          </li>
                          <li
                            className="dropdown-item border-0"
                            onClick={() => {
                              handleNetworkChange("Solana");
                              setOptions({ name: "Solana", image: solona });
                            }}
                          >
                            <div className="d-flex">
                              <div className="">
                                <img
                                  style={{ width: "44px" }}
                                  src={solona}
                                  alt=""
                                  className="image_searchManu"
                                />
                              </div>
                              <div className="textColordrp">
                                Solana
                                <br />
                                <span className="drpSpanText">ETH</span>
                              </div>
                            </div>
                          </li>
                        </ul>
                      </div>
                    </div>
                    <div className="col-md-8 feeLabel mt-md-0 mt-4">
                      <label htmlFor="fee">Fee Wallet</label>
                      <br />
                      <input
                        type="text"
                        className={`form-control input-control-result input-control-fee h-70`}
                        aria-label="Dollar amount (with dot and two decimal places)"
                        value={account ? account : ""}
                        placeholder="Enter Fee Wallet"
                        onChange={(e) => setAccount(e.target.value)}




                      // disabled={account?true:false}
                      />
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-12 addressLabel">
                      <label htmlFor="address">Token Contract Address</label>
                      <input
                        type="text"
                        className="form-control input-control-result input-control-fee h-70"
                        aria-label="Dollar amount (with dot and two decimal places)"
                        placeholder="Enter Token Contract Address"
                        onChange={handleTokenToSwapChange}
                        value={tokenToSwap}
                      />
                    </div>
                  </div>
                  <div className="row btnSection">
                    {loading ? (
                      <button className="btnContract mt-5" type="submit">
                        <span
                          className="spinner-border spinner-border-sm me-2"
                          role="status"
                          aria-hidden="true"
                        ></span>
                        Submit
                      </button>
                    ) : (
                      <button
                        className="btnContract mt-5"
                        type="submit"
                        onClick={handleCreateContract}
                      >
                        Submit
                      </button>
                    )}
                  </div>
                </form>
              </div>
            </div>
          </div>

          <div className="sectionResult">
            <div className="row">
              <div className="offset-md-2 col-md-8">
                {contractData?.map((data, index) => {
                  return (
                    <div className="input-group input-group-result mt-3">
                      <div className="d-flex d-flex-bg">
                        <div></div>
                        <div className="walletResultText text-center">
                          {!data?.name && firstFetch ? <div class="spinner-border" role="status" style={{ width: '15px', height: "15px" }}>
                            <span class="visually-hidden">Loading...</span>
                          </div> : data?.name}

                          <br />
                          <span className="qr-code-icon spanText"> {!data?.tokenAddressTemp && firstFetch ? <DotDotDotLoader /> : truncate(data?.tokenAddressTemp, 7)}
                            <div className="textAddress">
                              {data?.tokenAddressTemp}
                            </div>
                          </span>
                        </div>
                      </div>
                      <input
                        type="text"
                        id="input-control-result"
                        className="form-control input-control-result "
                        aria-label="Dollar amount (with dot and two decimal places)"
                        onClick={() => copyToClipboard(data?.contract_address, index)}
                        value={data?.contract_address}
                      />
                      <span className="input-group-text">
                        {showCheckmark === index ? (
                          <i className="fa-solid fa-check"></i>
                        ) : (
                          <i className="fa-solid fa-copy cursor-pointer" onClick={() => copyToClipboard(data?.contract_address, index)}></i>
                        )}
                      </span>
                      <span className="input-group-text qr-code-icon">
                        <i className="fa-solid fa-qrcode cursor-pointer"></i>
                        <div className="qr-code">
                          <QRCode value={data?.contract_address} size={120} />
                        </div>
                        {/* <div className="textAddress">
                          {data?.contract_address}
                        </div> */}
                      </span>
                      {/* {copied && (
                                                <span className="badge badge-success ms-2">Copied!</span>
                                            )} */}
                    </div>
                  );
                })}
              </div>
            </div>
          </div>
        </div>
      </section>
      {showModel && (
        <ConnectModel
          showModel={showModel}
          setShowModel={setShowModel}
          connectMetaMask={connectMetaMask}
        />
      )}
      {showComingsoon && (
        <ComingSoon
          showComingsoon={showComingsoon}
          setShowComingSoon={setShowComingSoon}
          comingSoonValue={comingSoonValue}
        />
      )}
      <Footer />
    </>
  );
};

const mapStateToProps = (state) => ({
  res_post_token: state.Token_by_influencer,
});

export default connect(mapStateToProps)(SwapToken);